@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

table {
  border-spacing: 0 2px;
}

input[type="text"]:focus {
  box-shadow: 0 0 0 transparent;
}

input[type="search"].expanded-search::-webkit-search-cancel-button {
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

@layer components {
  .button {
    @apply px-8 py-2 font-semibold text-white border-2 rounded-full shadow-md cursor-pointer border-blue-light bg-blue-light hover:bg-white hover:text-blue-500 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed;
  }

  .button-secondary {
    @apply px-8 py-2 font-semibold bg-blue-100 border-2 rounded-full shadow-md cursor-pointer border-blue-light text-blue-light hover:bg-white hover:text-blue-500 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed;
  }

  .foliko-bg {
    @apply bg-[length:1800px] bg-black bg-[url('/img/bg.webp')];
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
